<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card tile flat>
          <v-layout
            wrap
            justify-center
            px-8
            py-5
            style="background-color: #b6b6b626"
          >
            <v-flex xs11 text-left>
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 18px;
                  color: #393939;
                "
              >
                New Address
              </span>
            </v-flex>
            <v-flex xs1>
              <v-icon @click="closeDialog" color="#000000">mdi-close</v-icon>
            </v-flex>
          </v-layout>
          <v-layout
            wrap
            justify-center
            pt-8
            px-5
            style="font-size: 14px; color: #1f1f1f; font-family: RobotoRegular"
          >
            <v-flex xs12 sm12 text-left px-2>
              <span>Full Name </span>
              <v-text-field
                ref="firstname"
                background-color="#F5F5F5"
                solo
                flat
                dense
                :rules="[rules.required]"
                v-model="name"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 text-left px-2>
              <span>Address </span>
              <v-text-field
                ref="address"
                background-color="#F5F5F5"
                solo
                flat
                dense
                :rules="[rules.required]"
                v-model="address"
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs12 sm6 text-left px-2>
                <span> Locality </span>
                <v-text-field
                  ref="locality"
                                    background-color="#F5F5F5"

                  placeholder="Locality"
                   solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="locality"
                ></v-text-field>
              </v-flex> -->
            <v-flex xs12 sm6 text-left px-2>
              <span> City </span>
              <v-text-field
                ref="city"
                background-color="#F5F5F5"
                solo
                flat
                dense
                :rules="[rules.required]"
                v-model="city"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left px-2>
              <span> District </span>
              <v-text-field
                ref="district"
                background-color="#F5F5F5"
                solo
                flat
                dense
                :rules="[rules.required]"
                v-model="district"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left px-2>
              <span> State </span>
              <v-select
                ref="state"
                background-color="#F5F5F5"
                solo
                flat
                dense
                :items="states"
                item-text="name"
                item-value="value"
                :rules="[rules.required]"
                v-model="state"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 text-left px-2>
              <span> Country </span>
              <v-text-field
                ref="country"
                background-color="#F5F5F5"
                solo
                flat
                dense
                :rules="[rules.required]"
                v-model="country"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left px-2>
              <span> Pincode </span>
              <v-text-field
                ref="pincode"
                background-color="#F5F5F5"
                solo
                flat
                dense
                type="number"
                :rules="[rules.required]"
                v-model="pincode"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left px-2>
              <span> Phone </span>
              <v-text-field
                ref="phone"
                background-color="#F5F5F5"
                solo
                flat
                dense
                type="number"
                :rules="[rules.required]"
                v-model="phone"
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs12 sm6 text-left px-2>
                <span> Email </span>
                <v-text-field
                  ref="email"
                                    background-color="#F5F5F5"

                  placeholder="Email"
                   solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="email"
                ></v-text-field>
              </v-flex> -->
          </v-layout>
          <v-layout wrap justify-end py-5>
            <!-- <v-flex xs6 px-2>
                <v-btn
                  :ripple="false"
                  depressed
                  block
                  tile
                  color="#F9F9F9"
                  @click="closeDialog"
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 12px;
                      color: #000;
                      text-transform: none;
                    "
                  >
                    Cancel
                  </span>
                </v-btn>
              </v-flex> -->
            <v-flex xs6 px-2 text-right>
              <v-btn
                :ripple="false"
                depressed
                block
                tile
                color="#30B868"
                @click="validateInput"
              >
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 12px;
                    color: #fff;
                    text-transform: none;
                  "
                >
                  Add Address
                </span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      formHasErrors: false,
      timeout: 5000,
      msg: null,
      name: null,
      address: null,
      locality: null,
      city: null,
      district: null,
      state: null,
      country: null,
      pincode: null,
      phone: null,
      email: null,
      states:[],
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {
    form() {
      return {
        name: this.name,
        address: this.address,
        city: this.city,
        district: this.district,
        state: this.state,
        country: this.country,
        pincode: this.pincode,
        phone: this.phone,
      };
    },
    //   userData()
    //   {
    //     return this.$store.state.userData
    //   }
  },
  beforeMount() {
    //   if(this.userData)
    //   this.name = this.userData.firstname;
    //   this.lastname = this.userData.lastname;
    //   this.pincode =this.userData.pincode;
    //   this.phone =this.userData.phone;
    //   this.email =this.userData.email;
    this.getState()
  },
  methods: {
    closeDialog() {
      this.$emit("stepper", {
        ref: "AddAddress",
        addressAddDialog: false,
      });
    },
    validateInput() {
      this.formHasErrors = false;
      var phoneformat = /^\d{10}$/;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.msg = "Please Provide " + f;
          this.showSnackBar = true;
          return;
        }
        // if (!this.$refs[f].validate(true)) {
        //   this.msg = "Please Provide Valid " + f;
        //   this.showSnackBar = true;
        //   this.formHasErrors = true;
        //   return;
        // }
      });
      if (!this.phone.match(phoneformat)) {
        this.msg = "Please Provide valid Phone Number";
        this.showSnackBar = true;
        return;
      }
      if (this.formHasErrors == false) this.addAddress();
    },
    addAddress() {
      this.appLoading = true;
      var address = {};
      address["name"] = this.name;
      address["address"] = this.address;
      address["city"] = this.city;
      address["district"] = this.district;
      address["state"] = this.state;
      address["country"] = this.country;
      address["pincode"] = this.pincode;
      address["phone"] = this.phone;
      axios({
        method: "POST",
        url: "/deliveryAddress/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: address,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.name = null;
            this.address = null;
            this.city = null;
            this.district = null;
            this.state = null;
            this.country = null;
            this.pincode = null;
            this.phone = null;
            this.$emit("stepper", {
              ref: "AddAddress",
              addressAddDialog: false,
              response: response.data,
            });
          } else {
           this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getState() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/states/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            if (response.data.data) {
              this.states = response.data.data;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>