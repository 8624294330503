<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="editDialog" width="900" persistent>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card tile flat>
            <v-layout
              wrap
              justify-center
              px-8
              py-5
              style="background-color: #b6b6b626"
            >
              <v-flex xs11 text-left>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #393939;
                  "
                >
                  Edit Address
                </span>
              </v-flex>
              <v-flex xs1>
                <v-icon @click="editDialog = false" color="#000000"
                  >mdi-close</v-icon
                >
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              pt-8
              px-5
              style="
                font-size: 14px;
                color: #1f1f1f;
                font-family: RobotoRegular;
              "
            >
              <v-flex xs12 sm12 text-left px-2>
                <span>Full Name </span>
                <v-text-field
                  ref="firstname"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="editItem.name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 text-left px-2>
                <span>Address </span>
                <v-text-field
                  ref="address"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="editItem.address"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> City </span>
                <v-text-field
                  ref="city"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="editItem.city"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> District </span>
                <v-text-field
                  ref="district"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="editItem.district"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> State </span>
                <v-select
                  ref="state"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  item-text="name"
                  item-value="value"
                  :items="states"
                  :rules="[rules.required]"
                  v-model="editItem.state"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Country </span>
                <v-text-field
                  ref="country"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="editItem.country"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Pincode </span>
                <v-text-field
                  ref="pincode"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  type="number"
                  :rules="[rules.required]"
                  v-model="editItem.pincode"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Phone </span>
                <v-text-field
                  ref="phone"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  type="number"
                  :rules="[rules.required]"
                  v-model="editItem.phone"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end py-5>
              <v-flex xs6 px-2 text-right>
                <v-btn
                  :ripple="false"
                  depressed
                  block
                  tile
                  color="#30B868"
                  @click="editAddress"
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 12px;
                      color: #fff;
                      text-transform: none;
                    "
                  >
                    Edit Address
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card tile flat>
          <v-layout
            wrap
            justify-center
            px-8
            py-5
            style="background-color: #b6b6b626"
          >
            <v-flex xs11 text-left>
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 18px;
                  color: #393939;
                "
              >
                Change Delivery Address
              </span>
            </v-flex>
            <v-flex xs1>
              <v-icon @click="closeDialog" color="#000000">mdi-close</v-icon>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pa-5 v-if="address.length>0">
            <v-flex xs12 pa-2 v-for="(add, a) in address" :key="a">
              <v-card>
                <v-layout wrap justify-center py-5 px-8>
                  <v-flex xs2 sm1 md1 lg1>
                    <v-radio-group
                      :hide-details="true"
                      v-model="add.isDefault"
                      column
                    >
                      <v-radio
                        @click="changeDefault(add)"
                        color="#30B868"
                        :value="true"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs10 sm11 text-left>
                    <v-layout wrap justify-start fill-height>
                      <v-flex xs12 sm6 md5 lg5 text-left>
                        <v-layout
                          wrap
                          justify-start
                          style="line-height: 16px"
                          fill-height
                        >
                          <v-flex xs12>
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                                color: #000000;
                              "
                            >
                              {{ add.name }}
                            </span>
                          </v-flex>
                          <v-flex xs12 pt-3>
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >{{ add.address }}, {{ add.city }},
                              {{ add.district }}, {{ add.state }},
                              {{ add.country }},
                              {{ add.pincode }}
                            </span>
                          </v-flex>
                          <v-flex xs12 pt-2>
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >Mobile : {{ add.phone }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm6 md5 lg7 text-right>
                        <v-layout wrap justify-end pt-3>
                          <v-flex xs6 sm6 md3 align-self-end text-right>
                            <v-btn
                              outlined
                              small
                              color="#616161"
                              @click="(editDialog = true), (editItem = add)"
                              ><span
                                style="
                                  color: #616161;
                                  font-family: poppinsregular;
                                "
                              >
                                <v-icon small color="#616161"
                                  >mdi-pencil</v-icon
                                >
                                edit</span
                              ></v-btn
                            >
                          </v-flex>
                          <v-flex xs6 sm6 md3 align-self-end text-right>
                            <v-btn
                              outlined
                              small
                              color="#616161"
                              @click="removeAddress(add)"
                              ><span
                                style="
                                  color: #616161;
                                  font-family: poppinsregular;
                                "
                              >
                                <v-icon small color="#616161"
                                  >mdi-trash-can-outline</v-icon
                                >
                                Remove</span
                              ></v-btn
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center pt-5 v-else>
          <v-flex xs12 pa-2 align-self-center>
            <span
              style="
                font-family: poppinsregular;
                font-size: 20px;
                color: #000000;
                cursor: pointer;
              "
              >Oops! No Address Available</span
            >
          </v-flex>
        </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["address"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      formHasErrors: false,
      editItem: "",
      states: [],
      editDialog: false,
      timeout: 5000,
      msg: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 8 || "Min 8 characters",
      },
    };
  },
  beforeMount() {
    this.getState();
  },
  methods: {
    closeDialog() {
      this.$emit("stepper", {
        ref: "ChangeAddress",
        changeDialog: false,
      });
    },
    validateInput() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.msg = "Please Provide " + f;
          this.showSnackBar = true;
          return;
        }
        // if (!this.$refs[f].validate(true)) {
        //   this.msg = "Please Provide Valid " + f;
        //   this.showSnackBar = true;
        //   this.formHasErrors = true;
        //   return;
        // }
      });
      if (this.formHasErrors == false) this.addAddress();
    },
    editAddress() {
      this.appLoading = true;
      var address = {};
      address["id"] = this.editItem._id;
      address["name"] = this.editItem.name;
      address["address"] = this.editItem.address;
      address["city"] = this.editItem.city;
      address["district"] = this.editItem.district;
      address["state"] = this.editItem.state;
      address["country"] = this.editItem.country;
      address["pincode"] = this.editItem.pincode;
      address["phone"] = this.editItem.phone;
      axios({
        method: "POST",
        url: "/deliveryAddress/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: address,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editDialog = false;
            this.$emit("stepper", {
              ref: "editAddress",
              getAddress: true,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getState() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/states/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            if (response.data.data) {
              this.states = response.data.data;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    changeDefault(item) {
      this.appLoading = true;
      var address = {};
      address["id"] = item._id;
      axios({
        method: "POST",
        url: "/deliveryAddress/change",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: address,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$emit("stepper", {
              ref: "editAddress",
              getAddress: true,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    removeAddress(item) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/deliveryAddress/remove",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$emit("stepper", {
              ref: "editAddress",
              getAddress: true,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>