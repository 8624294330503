<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-dialog v-model="addressAddDialog" width="700" persistent>
      <!-- <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12> -->
      <AddAddress @stepper="winStepper" />
      <!-- </v-flex>
        </v-layout>
      </v-card> -->
    </v-dialog>
    <v-dialog v-model="addressEditDialog" width="500" persistent>
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <EditAddress
              v-bind:storage="editingAddress"
              @stepper="winStepper"
              :key="editingAddress._id"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeDialog" width="900" persistent>
      <!-- <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12> -->
      <ChangeAddress @stepper="winStepper" :address="address" />
      <!-- </v-flex>
        </v-layout>
      </v-card> -->
    </v-dialog>
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right> </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 lg10 xl10 px-0 px-sm-4 pt-4 px-2 pr-lg-12 pr-xl-0>
        <v-layout wrap justify-center pt-16>
          <v-flex
            xs12
            sm12
            lg6
            xl6
            text-center
            text-uppercase
            style="font-family: poppinsmedium; font-size: 15px"
          >
            <router-link to="/Cart">
              <span style="color: #30b868"> Cart </span>
            </router-link>
            <span class="pl-4" style="letter-spacing: 10px; color: #30b868">
              &bullet;&bullet;&bullet;&bullet;&bullet;
            </span>
            <span class="pl-4" style="color: #30b868"> ADDRESS </span>
            <span class="pl-4" style="letter-spacing: 10px; color: #a39f9f">
              &bullet;&bullet;&bullet;&bullet;&bullet;
            </span>
            <span class="pl-4" style="color: #a39f9f"> PAYMENT </span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start pt-10 pb-5>
          <v-flex xs12 sm12 md8 lg8 xl8>
            <v-card @click="addressAddDialog = true">
              <v-layout wrap py-5 px-5>
                <v-flex xs12 text-left>
                  <span
                    style="
                      font-family: poppinsmedium;
                      font-size: 14px;
                      color: #4c4c4c;
                      text-transform: none;
                    "
                  >
                    + Add New Address
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start>
          <v-flex xs12 sm12 md8 lg8 xl8 pt-4>
            <v-layout wrap pb-5 v-if="defaultAddress">
              <v-flex xs12>
                <v-card>
                  <v-layout wrap justify-center py-5 px-8>
                    <v-flex xs2 sm1 md1 lg1>
                      <v-radio-group
                        :hide-details="true"
                        column
                        v-model="selected"
                      >
                        <v-radio
                          color="#30B868"
                          :value="true"
                        ></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <v-flex xs10 sm11 text-left>
                      <v-layout wrap justify-start fill-height>
                        <v-flex xs12 sm6 md5 lg8 text-left>
                          <v-layout
                            wrap
                            justify-start
                            style="line-height: 16px"
                            fill-height
                          >
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: poppinssemibold;
                                  font-size: 15px;
                                  color: #000000;
                                "
                              >
                                {{ defaultAddress.name }}
                              </span>
                            </v-flex>
                            <v-flex xs12 pt-3>
                              <span
                                style="
                                  color: #191919;
                                  font-size: 13px;
                                  font-family: poppinsmedium;
                                "
                                >{{ defaultAddress.address }}, {{ defaultAddress.city }},
                                {{ defaultAddress.district }}, {{ defaultAddress.state }},
                                {{ defaultAddress.country }},
                                {{ defaultAddress.pincode }}
                              </span>
                            </v-flex>
                            <v-flex xs12 pt-2>
                              <span
                                style="
                                  color: #191919;
                                  font-size: 13px;
                                  font-family: poppinsmedium;
                                "
                                >Mobile : {{ defaultAddress.phone }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md5 lg4 align-self-center text-right>
                          <v-btn outlined small color="#30B868" @click="changeDialog=true"
                            ><span
                              style="
                                color: #30b868;
                                font-family: poppinsregular;
                              "
                            >
                              Change address</span
                            ></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pb-10 v-if="cart.length > 0">
              <v-card>
                <v-layout wrap>
                  <v-flex
                    xs12
                    sm12
                    pa-2
                    v-for="(item, i) in cart"
                    :key="i"
                    text-left
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <CartItem
                          v-bind:storage="item"
                          :list="list"
                          @stepper="winStepper"
                        />
                      </v-flex>
                      <v-flex xs12 py-4 v-if="i < cart.length - 1">
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-layout>
            <!-- <v-layout wrap justify-center v-if="defaultAddress._id">
                <v-flex xs12 text-left>
                  <span
                    style="
                      font-family: poppinsmedium;
                      font-size: 14px;
                      color: #919191;
                    "
                  >
                    SELECTED ADDRESS
                  </span>
                </v-flex>
                <v-flex xs12 text-left pt-4>
                  <AddressItem
                    v-bind:storage="defaultAddress"
                    v-bind:defaultAddress="defaultAddress"
                    v-bind:type="'default'"
                    @stepper="winStepper"
                  />
                </v-flex>
                <v-flex xs12 py-4>
                  <v-divider></v-divider>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span
                    style="
                      font-family: poppinsmedium;
                      font-size: 14px;
                      color: #919191;
                    "
                  >
                    SAVED ADDERSS
                  </span>
                </v-flex>
                <template v-for="item in address">
                  <v-flex
                    xs12
                    :key="item._id"
                    v-if="defaultAddress != item"
                    text-left
                    pt-4
                  >
                    <v-layout wrap justify-center>
                      <v-flex>
                        <AddressItem
                          v-bind:storage="item"
                          v-bind:defaultAddress="defaultAddress"
                          v-bind:type="'address'"
                          @stepper="winStepper"
                          :key="item._id"
                        />
                      </v-flex>
                      <v-flex xs12 py-4>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
              </v-layout> -->
          </v-flex>
          <v-flex xs12 sm12 md4 lg4 xl4 pt-4>
            <OrderDetails
              v-bind:storage="cart"
              v-bind:address="defaultAddress"
              :promocode="promocode"
              v-bind:page="'address'"
              :summary="summary"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
import CartItem from "./cartItem";
import AddAddress from "./addAddress";
import ChangeAddress from "./changeAddress"
//   import EditAddress from "./editAddress";
//   import AddressItem from "./addressItem";
import OrderDetails from "./orderDetails";
export default {
  components: {
    AddAddress,
    //   EditAddress,
    //   AddressItem,
    OrderDetails,
    ChangeAddress,
    CartItem,
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      addressAddDialog: false,
      addressEditDialog: false,
      changeDialog:false,
      products: [],
      cart: [],
      summary: {},
      selected:true,
      list: [],
      address: [],
      promocode:"",
      defaultAddress: {},
      ServerError: false,
      searchKey: null,
      editingAddress: {
        _id: null,
      },
    };
  },
  computed: {
    appLogin() {
      if (this.$store.state.isLoggedIn) return this.$store.state.isLoggedIn;
      else return null;
    },
  },
  beforeMount() {
    this.getCart();
    this.getAddress();
  },
  methods: {
    winStepper(item) {
      if (item.getData) {
        this.getCart();
      }
      if (item.ref == "AddAddress") {
        this.addressAddDialog = item.addressAddDialog;
        this.msg = item.response.msg;
        this.showSnackBar = true;
        this.getAddress();
        this.getCart();
      }
      if (item.ref == "editAddress") {
        this.getAddress();
        this.getCart();
      }
      if (item.ref == "ChangeAddress") {
        this.changeDialog = item.changeDialog;
        // this.msg = item.response.msg;
        // this.showSnackBar = true;
        this.getCart();
      }
      if (item.ref == "AddressItem") {
        this.deteleAddress(item.address);
      }
      if (item.ref == "DefaultAddressItem") {
        this.setDefaultAddress(item.address);
      }
      if (item.ref == "EditAddress") {
        if (item.address) {
          this.editingAddress = item.address;
        }
        this.addressEditDialog = item.addressEditDialog;
        if (!item.addressEditDialog) {
          if (item.response) {
            this.msg = item.response.msg;
            this.showSnackBar = true;
          }
          this.getAddress();
        }
      }
    },
    getCart() {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/cart",
        method: "GET",
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          this.cart = response.data.items;
          this.summary = response.data.summary;
          if(response.data.promocode.length>0){
          this.promocode=response.data.promocode[0]
          console.log("haii",this.promocode)
          }
          this.defaultAddress=response.data.selectedDeliverAddress
          this.$store.commit("changeCart", response.data.totalLength);
          for (var i = 0; i <= this.cart.length; i++) {
            for (var l = 1; l <= this.cart[i].itemId.stock[0].stock; l++) {
              this.list.push(l);
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          // this.ServerError = true;
          console.log(err);
        });
    },
    getAddress() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/deliveryAddress/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            if (response.data.data) {
              this.address = response.data.data;
              // this.defaultAddress = this.address.find((item) => {
              //   return item._id == response.data.data.deliveryaddressindex;
              // });
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
  